import { render, staticRenderFns } from "./StatusOffersComponent.vue?vue&type=template&id=dd44a2e6&scoped=true&origin=center%20center&"
import script from "./StatusOffersComponent.vue?vue&type=script&lang=js&"
export * from "./StatusOffersComponent.vue?vue&type=script&lang=js&"
import style0 from "./StatusOffersComponent.vue?vue&type=style&index=0&id=dd44a2e6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd44a2e6",
  null
  
)

export default component.exports